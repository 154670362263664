$enable-shadows: true;
$headings-font-weight: 600;
$display4-weight: 900;
$display3-weight: 500;
$display2-weight: 500;
$display1-weight: 500;
$btn-border-radius: 3rem;
$enable-gradients: true;
$font-size-base: 1rem;
$input-btn-font-size: $font-size-base *1.2;
$input-btn-padding-y: 1.125rem;
$input-btn-padding-x: 4rem;
$btn-font-weight: 900;
$btn-box-shadow: 0.2rem 0.2rem #000;
$navbar-nav-link-padding-x: 1.75rem;
$purple: #6f42c1;
$navbar-light-color: rgba($purple, .5);
$navbar-light-hover-color: rgba($purple, .7);
$navbar-light-active-color: rgba($purple, .9);
$navbar-light-disabled-color: rgba($purple, .3);
$navbar-light-toggler-border-color: rgba($purple, .7);
$input-btn-focus-color: #007BFF;
$yellow: #ffc107;
$warning: $yellow;
$gray-100: #f8f9fa;
$cyan: #17a2b8;
$primary: #0049FF;
$black: #000;
$white: #fff;
$box-shadow: 1 .5rem 1rem rgba($black, .15);
$box-shadow-sm: 1 .25rem .25rem rgba($black, 1.75);
$body-bg: $white;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$enable-rounded: true;
$kbd-box-shadow: 0 .5rem 0 0 rgba($white, .25);
$lead-font-weight: 300;
$font-weight-light: 300;
$font-weight-normal: 700;
$font-weight-bold: 800;
